import { React, useState, useEffect } from 'react';
import axios from 'axios';
import apiUrl from '../../../apiConfig';
import EmailInput from './EmailInput';
import RegistrationModal from './RegistrationModal';
import { Row, Container, Button, Col } from 'react-bootstrap';
import ScoresModal from './ScoresModal';
import TermsModal from './Terms/TermsModal';

const PlayerAuth = (props) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [needToRegister, setNeedToRegister] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [email, setEmail] = useState('');
    const [scoresModal, setScoresModal] = useState(false);
    const [termsModal, setTermsModal] = useState(false);
    const [noMorePlays, setNoMorePlays] = useState(false);

    const promptRegistration = (email) => {
        setNeedToRegister(true);
        setEmail(email);
    }

    const closeRegistration = () => {
        setNeedToRegister(false);
        setEmail('');
    }

    const openScoresModal = () => {
        setScoresModal(true);
    }

    const closeScoresModal = () => {
        setScoresModal(false);
    }

   const closeTermsModal = () => {
        setTermsModal(false);
    }

    useEffect(() => {
        axios({
            url: `${apiUrl}/plays/${props.player.email}/${props.quiz[0].id}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_SECRET_KEY}`
            }
        })
            .then((res) => {
                if (res.data.data >= 30) {
                    setNoMorePlays(true);
                }
            })
            .catch(() => {
                props.msgAlert({
                    heading: 'Server Error',
                    message: 'Please try again later.',
                    variant: 'danger'
                })
            })
    }, []);

    

    return (
        <>
        {termsAccepted &&
            <h1>Terms Accepted</h1>
        }
        {authenticated &&
            <Container className='d-flex flex-column'>
                <Row className="d-flex mb-3 justify-content-center">
                    {noMorePlays &&
                        <h3 style={{ textAlign: 'center' }}>Sorry, you have reached the maximum amount of plays for this game.</h3>
                    }
                    {!noMorePlays &&
                    <>
                    <Col xs={12} md={6} className="d-flex justify-content-end ">
                    <Button onClick={()=> openScoresModal()} style={{ width: '50%', borderRadius: '0' }} variant="light" className='mt-1'>Previous Scores</Button>
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-start">
                    <Button style={{ width: '50%', borderRadius: '0' }} variant="light" className='mt-1' onClick={!termsAccepted ? ()=> setTermsModal(true) : console.log('nothing') }>Play</Button>
                    </Col>
                    </>
                    }
                </Row>
                {scoresModal &&
                <ScoresModal {...props} scoresModal={scoresModal} closeScoresModal={closeScoresModal} />
                }
                {termsModal &&
                <TermsModal {...props} termsAccepted={termsAccepted} setTermsAccepted={setTermsAccepted} termsModal={termsModal} closeTermsModal={closeTermsModal} /> 
                }
            </Container>
        }
        {!authenticated &&
            <div>
                <EmailInput {...props} promptRegistration={promptRegistration} setAuthenticated={setAuthenticated}/>
                {needToRegister &&
                <RegistrationModal {...props} closeRegistration={closeRegistration} needToRegister={needToRegister} currentEmail={email} setAuthenticated={setAuthenticated}/> 
                }
            </div>
        }
        </>
    );
};

export default PlayerAuth;